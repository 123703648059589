<template>
    <!-- 预出库汇总 -->
    <div class="preDeliverySummary">
        <!-- 导出模板 -->
        <div id="export" style="position: fixed;top: -9999999px;">

            <div :id="'template-title'">
                <div style="line-height: 50px;font-size: 20px;" align=center>
                    {{ searchForm.begin }}至{{ searchForm.end }}待配送商品
                </div>
            </div>
            <div :id="'template-table'">
                <table border=1 cellSpacing=0 cellPadding=1 width="100%" style="border-collapse:collapse;font-size:14px;"
                    bordercolor="#333333">
                    <thead>
                        <tr>
                            <td width="10%">
                                <div align=center><b>序号</b></div>
                            </td>
                            <td width="30%">
                                <div align=center><b>商品名称</b></div>
                            </td>
                            <td width="10%">
                                <div align=center><b>自定义属性</b></div>
                            </td>
                            <td width="10%">
                                <div align=center><b>数量</b></div>
                            </td>
                            <td width="10%">
                                <div align=center><b>销售价</b></div>
                            </td>
                            <td width="10%">
                                <div align=center><b>成本价</b></div>
                            </td>
                            <td width="20%">
                                <div align=center><b>商品简介</b></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <div class="export-template" v-for="(item, index) in selectionList" :key="index">
                            <tr>
                                <td align=center>{{ index + 1 }}</td>
                                <td align=center>{{ item.goods_name }}</td>
                                <td align=center>{{ item.goods_attr }}</td>
                                <td align=center>{{ item.count }}</td>
                                <td align=center>{{ item.price }}</td>
                                <td align=center>{{ item.cost_price }}</td>
                                <td align=center>{{ item.goods_intro }}</td>
                            </tr>
                        </div>
                    </tbody>
                </table>

            </div>
        </div>
        <div class="search">
            <div class="search-l">
                <div class="l-item" style="width: 40%;">
                    <div class="item-label">配送日期:</div>
                    <div class="item-input" style="width: 80%;">
                        <el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
                        </el-date-picker>
                    </div>
                </div>
                <div class="l-item">
                    <div class="item-label">商品名称:</div>
                    <div class="item-input">
                        <el-input v-model="searchForm.goods_name" placeholder="请输入商品名称" />
                    </div>
                </div>
                <div class="l-item">
                    <div class="item-label">提货方式:</div>
                    <div class="item-input">
                        <el-select v-model="searchForm.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
                            <el-option v-for="item in deliveryTypeList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="l-item">
                    <el-button type="warning"
                        @click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
                    <el-button
                        @click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
                </div>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
        </div>
        <div class="table">
            <div class="table-operation">
                <el-button type="success" @click="handlePrint">打印</el-button>
                <el-button type="warning" @click="handleExport">导出</el-button>
                <el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
            </div>
            <el-table :data="tableData" style="width: 100%" border :header-cell-style="{
                background: '#f5f7fa',
                fontWeight: 'bold',
                color: '#303133'
            }">
                <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
                <el-table-column prop="goods_attr" label="自定义属性" align="center"></el-table-column>
                <el-table-column prop="count" label="数量" align="center"></el-table-column>
                <el-table-column prop="price" label="销售价" align="center"></el-table-column>
                <el-table-column prop="cost_price" label="成本价" align="center"></el-table-column>
                <el-table-column prop="goods_intro" label="商品简介" align="center"></el-table-column>
            </el-table>
        </div>
    </div>
</template>
    
<script>
import { getLodop } from '@/utils/CLodopfuncs.js'
export default {
    data() {
        return {
            dateVal: [],
            searchForm: {
                begin: '',
                end: '',
            },
            tableData: [],
            loading: true,
            page: 1,
            pageNum: 10,
            total: 0,
            selectionList: [],
            // 提货方式
            deliveryTypeList: [
                {
                    id: 1,
                    name: '自提'
                },
                {
                    id: 2,
                    name: '配送'
                }
            ],
            routeList: [],//配送路线
            routeName: '',
            delayDialogVisible: false,
            delayForm: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
        }
    },
    mounted() {
        // 时间
        this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
        this.searchForm.begin = this.dateVal[0]
        this.searchForm.end = this.dateVal[1]
        // 列表
        this.getList()
        this.getRouteList()
    },
    methods: {
        getList() {
            this.$http.post(`erp/v1/purchase/pre_ck_summary`, {
                page: this.page,
                pageNum: this.pageNum,
                ...this.searchForm
            }).then((res) => {
                const { code, data, msg } = res.data
                this.loading = false
                if (code == 200) {
                    this.tableData = data
                    this.selectionList = data
                    this.total = data.total
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 获取当前年月日
        getCurrentDate() {
            const today = new Date();
            let year = today.getFullYear();
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        // 所属路线
        getRouteList() {
            this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
                const { code, data, msg } = res.data
                if (code == 200) {
                    this.routeList = data
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 延期
        handleDelay(item) {
            this.delayDialogVisible = true
            this.delayForm.pre_ckd_mx_id = item.id
        },
        // 延期确认
        delayConfirmFn() {
            this.$http.post(`erp/v1/preck/goods_delay`, {
                ...this.delayForm
            }).then((res) => {
                const { code, data, msg } = res.data
                if (code == 200) {
                    this.delayDialogVisible = false
                    this.getList()
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 打印
        handlePrint(item) {
            if (item.goods_id) {
                this.selectionList = []
                this.selectionList.push(item)
            } else {
                if (this.selectionList.length <= 0) {
                    this.$message.error('请选择打印数据');
                    return
                }
            }
            setTimeout(() => {
                var LODOP = getLodop();
                var titleStyle = "<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
                LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById("export").innerHTML);
                LODOP.PREVIEW()
                this.selectionList = []
                this.getList()
            }, 500);
        },
        // 导出
        handleExport() {
            this.$http.post(`erp/v1/purchase/pre_ck_summary`, {
                page: this.page,
                pageNum: this.pageNum,
                ...this.searchForm,
                export: 1
            }).then((res) => {
                const { code, data, msg } = res.data
                this.loading = false
                if (code == 200) {
                    window.open(data.url, '_self')
                } else {
                    this.$message.error(msg);
                }
            });
        },
    },
}
</script>
    
<style lang="scss" scoped></style>